<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">

                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="Name">
                        <span class="required">*</span> Chave:
                      </label>
                      <input autocomplete="new-password" type="text" id="Name" class="form-control"
                        v-model="product.key" placeholder="" />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="Email">
                        <span class="required">*</span> Código:
                      </label>
                      <input autocomplete="new-password" class="form-control" id="Email" placeholder="" type="text"
                        v-model="product.code" />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <label for="Name">
                        <span class="required">*</span> Descrição:
                      </label>
                      <input autocomplete="new-password" type="text" class="form-control"
                        v-model="product.description" />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-3">
                      <label for="Cellphone">Tipo:</label>
                      <input autocomplete="new-password" class="form-control" type="text" v-model="product.type" />
                    </div>
                    <div class="form-group col-md-3">
                      <label for="Cellphone">Dimensão:</label>
                      <input autocomplete="new-password" class="form-control" type="text" v-model="product.dimension" />
                    </div>
                    <div class="form-group col-md-3">
                      <label for="Cellphone">Grão:</label>
                      <input autocomplete="new-password" class="form-control" type="text" v-model="product.grain" />
                    </div>
                    <div class="form-group col-md-3">
                      <label for="Cellphone">Furos:</label>
                      <input autocomplete="new-password" class="form-control" type="text" v-model="product.holes" />
                    </div>
                    <div class="form-group col-md-3">
                      <label for="Cellphone">Composição:</label>
                      <select class="form-control" v-model="product.mixed_or_pure">
                        <option value=""></option>
                        <option value="PURA">PURA</option>
                        <option value="MISTA">MISTA</option>
                      </select>
                    </div>
                  </div>

                  <span class="required_fields">
                    <span class="required">*</span>
                    <strong>Campos obrigatórios</strong>
                  </span>
                  <div class="block text-right">
                    <router-link to="/products" class="btn btn-back">
                      <i class="fa fa-arrow-left"></i> Voltar
                    </router-link>
                    <a class="btn btn-add ml-1" @click="save">
                      Salvar
                      <i class="fa fa-save"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  data() {
    return {
      product: {},
      endereco: {},
      naoLocalizado: false
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "products";

      if (self.product.id) {
        api += "/" + self.product.id;

        self.product._method = 'PUT';
      }

      axios
        .post(api, self.product)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/products");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEntity: function (id) {
      const self = this;
      const api = self.$store.state.api + "products/" + id;

      axios
        .get(api)
        .then((response) => {
          self.product = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

  },
  mounted: function () {
    const self = this;

    let id = self.$route.params.id;
    if (id) {
      self.getEntity(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped></style>